import api from '../api';


const storeService = {

    getStore(store_id) {
        return api.get(`/store?store_id=${store_id}`);
    },

    updateStore(store_id, store) {
        return api.post(`/store/update?store_id=${store_id}`,store);
    },

    updateStoreLogo(store_id, formData) {
        return api.post(`/store/logo?store_id=${store_id}`, formData);
    },
    
}

export default storeService;