var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"reviews"},[_vm._m(0),_c('div',{staticClass:"reviews__actions"},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CButtonGroup',[_c('CButton',{staticClass:"button",attrs:{"color":"info"},on:{"click":function($event){return _vm.updateReviewsListStatus('all')}}},[_vm._v("Todos")]),_c('CButton',{staticClass:"button",attrs:{"color":"success"},on:{"click":function($event){return _vm.updateReviewsListStatus(1)}}},[_vm._v("Aprobados")]),_c('CButton',{staticClass:"button",attrs:{"color":"warning"},on:{"click":function($event){return _vm.updateReviewsListStatus(0)}}},[_vm._v("Ocultos")]),_c('CButton',{staticClass:"button",attrs:{"color":"danger"},on:{"click":function($event){return _vm.updateReviewsListStatus(2)}}},[_vm._v("En spam")])],1)],1)],1)],1),(_vm.isLoading)?_c('Loader',{attrs:{"absolute":false}}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"reviews__content"},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"dataTable"},[_c('CDataTable',{attrs:{"noItemsView":{ noResults: 'Sin resultados', noItems: 'Sin resultados' },"items":_vm.reviews,"fields":_vm.fields,"items-per-page":25,"columnFilter":false,"items-per-page-select":{label: 'Items por página', values: [25, 50, 100, 500, 1000]},"tableFilter":false,"sorterValue":{ column: 'createdAt', asc: false },"sorter":{ external: false, resetable: true },"loading":_vm.isLoading,"pagination":""},scopedSlots:_vm._u([{key:"order_number",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{staticClass:"link -noUnderline",attrs:{"href":_vm.store.url + '/admin/orders/' + item.order.order_id,"target":"_blank"}},[_vm._v("#"+_vm._s(item.order.number))])])]}},{key:"product_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"external-link"},[_c('router-link',{attrs:{"to":'/producto/' + item.product_id}},[_vm._v(_vm._s(item.product.name)+" "),_c('CIcon',{attrs:{"name":"cil-external-link"}})],1)],1)]}},{key:"rating",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.rating)+" / 5")])])]}},{key:"comment",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.comment)?_c('span',[_vm._v(_vm._s(item.comment))]):_c('span',[_vm._v("Sin comentarios")])])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"badge-container"},[_c('CBadge',{attrs:{"color":_vm.getBadgeColor(item.status)}},[_vm._v(_vm._s(_vm.parseStatusText(item.status)))])],1)])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(new Date(item.createdAt).getDate() + '/' +(new Date(item.createdAt).getMonth() + 1 )+ '/'+new Date(item.createdAt).getFullYear()))])])]}},{key:"acciones",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButtonGroup',[(item.status == 0 || item.status == 2)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Aprobar'),expression:"'Aprobar'"}],key:0 + item.id,attrs:{"color":"success"},on:{"click":function($event){return _vm.changeReviewStatus(1,item)}}},[_c('CIcon',{attrs:{"name":"cil-check"}})],1):_vm._e(),(item.status == 1 || item.status == 2)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Ocultar'),expression:"'Ocultar'"}],key:1 + item.id,attrs:{"color":"warning"},on:{"click":function($event){return _vm.changeReviewStatus(0,item)}}},[_c('CIcon',{attrs:{"name":"cil-low-vision"}})],1):_vm._e(),(item.status != 2)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Enviar a spam'),expression:"'Enviar a spam'"}],key:2 + item.id,attrs:{"color":"danger"},on:{"click":function($event){return _vm.changeReviewStatus(2,item)}}},[_c('CIcon',{attrs:{"name":"cil-ban"}})],1):_vm._e()],1)],1)]}}],null,false,693321819)})],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reviews__title"},[_c('div',{staticClass:"titleBlock -noMarginTop -noMarginBottom"},[_c('h1',{staticClass:"titleBlock__title"},[_vm._v(" Calificaciones ")]),_c('p',{staticClass:"titleBlock__subtitle"},[_vm._v(" Navegá y moderá todas las calificaciones de tus clientes. ")])])])}]

export { render, staticRenderFns }