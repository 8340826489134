<template>
  <div class="auth">
    <div class="container">
      <Loader v-if="isLoading"  />
      
      <div class="messageBox">
        <div class="titleBlock -center">
          <div class="titleBlock__image">
            <img src="assets/img/logo.svg" alt="CalificApp">
          </div>
          <p class="titleBlock__subtitle">Accede a las estadisticas y configuración de tu plugin haciendo click debajo</p>
          <div class="titleBlock__actions">
            <CButton color="primary" class="button -big" href="https://www.tiendanube.com/apps/3785/authorize" >Ingresar con TiendaNube</CButton>
          </div>
        </div><!-- titleBlock -->
      </div><!-- messageBox -->

    </div><!-- container -->
    
  </div><!-- auth -->
</template>

<script>
// @ is an alias to /src
import tnService from '@/services/tiendanube.service';
import Loader from '@/views/components/Loader.vue';

export default {
  name: 'Auth',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Ingresa a la app | CalificApp',
    // all titles will be injected into this template
    titleTemplate: null
  },
  components: {
    Loader,
  },
  data() {
    return {
     response: {},
     isLoading: true,
    }
  },
  created() {
    this.validateStore();
  },
  methods: {
    validateStore: async function(){
      const code = this.$route.query.code;
      if(code){

        try {
          const { data } = await tnService.auth(code);
          const store = data.data.store;
          const token = data.data.token;
          const registered = data.data.new;

          this.$store.dispatch('loadStore', { store, token }).then(() => {
            this.$router.push({ path: `/`, query: registered ? { registered } : {} });
          });
          
        } catch (error) {

          console.log('Hubo un error al procesar el código ', error);
          this.isLoading = false;

          this.$router.push({ path: `/tiendanube` });
          
        }

      }else{
        this.isLoading = false;
        console.log('Codigo inexistente');
      }
    },
  },
}
</script>
