<template>
  <div class="dashboard">
    Página principal de la app
  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  components: {
   
  },
  data () {
    return {
      
    }
  },
  mounted() {
   
  },
  methods: {
   
  }
}
</script>
