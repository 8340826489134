<template>
    <CElementCover :class="['loader', absolute ? '' : '-noAbsolute' ]"  :opacity="opacity" >
        <div class="d-flex flex-column align-items-center justify-center ">
            <CSpinner
                color="primary"
                style="width:4rem;height:4rem;"
            />
            <p class="mt-3 d-block">Cargando... </p>
        </div>
    </CElementCover>
</template>

<script>

export default {
  name: 'Loader',
    props: {
        opacity: {
            type: Number,
            default: 1
        },
        absolute: {
            type: Boolean,
            default: true
        }
    },  
  components: { },
}
</script>