import api from '../api';


const reviewService = {

    getReviews(store_id) {
        return api.get(`/review/?store_id=${store_id}`,{
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        });
    },

    getReviewsByStatus(store_id,status) {
        return api.get(`/review/?store_id=${store_id}&status=${status}`, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        });
    },

    getProductReviewsByStatus(store_id,product_id,status) {
        return api.get(`/review/?store_id=${store_id}&product_id=${product_id}&status=${status}`, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        });
    },

    submitReview(data) {
        return api.post(`/review/`,data);
    },

    updateStatus(data) {
        return api.post(`/review/updateStatus`,data);
    },

    updateStatusApproveAll(data) {
        return api.post(`/review/updateStatus/approve/all`,data);
    },

    updateStatusDenyAll(data) {
        return api.post(`/review/updateStatus/deny/all`,data);
    },
}

export default reviewService;