<template>
  <section class="pedidos">

    <div class="pedidos__title">
      <div class="titleBlock -noMarginTop -noMarginBottom">
        <h1 class="titleBlock__title">
          Pedidos
        </h1>
        <p class="titleBlock__subtitle">
          Todos los pedidos de tu tienda
        </p>
      </div>
    </div><!-- pedidos__title -->

    <Loader :absolute="false" v-if="isLoading">
    </Loader>

    <div v-if="!isLoading" class="pedidos__content">
      <CRow>
        <CCol sm="12">
          <div class="dataTable">
            <CDataTable
            :items="orders"
            :fields="fields"
            :items-per-page="25"
            :columnFilter="false"
            :items-per-page-select="{label: 'Items por página', values: [25, 50, 100, 500, 1000]}"
            :tableFilter="false"
            :sorterValue="{ column: 'createdAt', asc: false }"
            :sorter='{ external: false, resetable: true }'
            :loading="isLoading"
            pagination
          >
          <template #order_number="{item}">
            <td>
              <a class="link -noUnderline" :href="store.url + '/admin/orders/' + item.order_id" target="_blank">#{{item.number}}</a>
            </td>
          </template>
          <template #customer_name="{item}">
            <td>
              <span>{{item.customer.name}}</span>
            </td>
          </template>
          <template #customer_email="{item}">
            <td>
              <span>{{item.customer.email}}</span>
            </td>
          </template>
          <template #product_name="{item}">
            <td class="external-link">
              <router-link :to="'/producto/' + item.product_id">{{item.product.name}} <CIcon name="cil-external-link" ></CIcon> </router-link>
            </td>
          </template>
          <template #sent_review="{item}">
            <td>
              <div class="badge-container">
                <CBadge :color="getBadgeColor(item.sent_review)">{{parseSentReviewText(item)}}</CBadge>
              </div>
            </td>
          </template>
          <template #reviewed="{item}">
            <td>
              <div class="badge-container">
                <CBadge :color="getBadgeColor(item.reviewed)">{{parseReviewedText(item.reviewed)}}</CBadge>
              </div>
            </td>
          </template>
          <template #createdAt="{item}">
            <td>
              <span>{{new Date(item.createdAt).getDate() + '/' +(new Date(item.createdAt).getMonth() + 1 )+ '/'+new Date(item.createdAt).getFullYear()}}</span>
            </td>
          </template>
          </CDataTable>
          </div><!-- table -->
        </CCol><!-- col -->
      </CRow><!-- row -->
      
    </div><!-- pedidos__content -->

  </section><!-- pedidos -->
</template>

<script>
import orderService from '../../services/order.service';

import Loader from '@/views/components/Loader.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'Pedidos',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Pedidos | CalificApp',
    // all titles will be injected into this template
    titleTemplate: null
  },
  components: {
    Loader
  },
  data() {
      return {
          fields: this.generateFields(),
          orders: [{}],
          isLoading: true
      }
  },
  created() {
    this.getOrders();
  },
  methods: {
    getOrders: async function(){
      
      const {store_id} = this.store;

      const response = await orderService.getOrders(store_id);

      const { data } = response.data;

      this.orders = data;

      this.isLoading = false;

    },

    generateFields(){
      const fields = [{
        key: 'order_number' ,
        label: 'Pedido',
        filter: true,
      },{
        key: 'customer_name' ,
        label: 'Cliente',
        filter: true,
      },{
        key: 'customer_email' ,
        label: 'Correo electrónico',
        filter: true,
      },{
        key: 'sent_review' ,
        label: 'Solicitud de calificación',
        filter: false,
      },{
        key: 'reviewed' ,
        label: 'Calificación',
        filter: false,
      },{
        key: 'createdAt' ,
        label: 'Fecha',
        filter: false,
      }];

      return fields;
    },

    getBadgeColor(state) {
      switch(state){
        case false:
          return 'danger';
        case true:
          return 'success';
      }
    },
    addDays(date, days) {
      const copy = new Date(Number(date))
      copy.setDate(date.getDate() + days)
      return copy
    },
    parseSentReviewText(order){
      const date1 = new Date(order.createdAt);
      const date2 = this.addDays(date1, this.config.review_email_days);
      const today = new Date();

      var diffDays = parseInt(( date2 - today) / (1000 * 60 * 60 * 24));
              
      switch(order.sent_review){
        case true:
          return 'Enviada';
        case false:
          if(diffDays < 0){
            return 'Listo para envíar'
          }else{
            return `Se envia en ${diffDays} días`;
          }
          
      }
    },
    parseReviewedText(state){
      switch(state){
        case true:
          return 'Realizada';
        case false:
          return 'No realizada';
      }
    },
  },
  computed: {
    ...mapGetters({
      config: 'getConfig',
      store: 'getStore',
    }),
  },
}
</script>
