<template>
    <section class="reviewForm">
      <Loader v-if="isLoading">
      </Loader>
      <div class="reviewForm__shapes">
        <img class="shape-azul" src="/assets/img/form/shape-azul.svg" alt="">
        <img class="shape-naranja" src="/assets/img/form/shape-naranja.svg" alt="">
        <img class="shape-lila" src="/assets/img/form/shape-lila.svg" alt="">
        <img class="shape-verde" src="/assets/img/form/shape-verde.svg" alt="">
      </div>
      <div class="container">
        <div class="reviewForm__notice" v-if="!isSent">
          <div class="reviewForm__header" v-if="this.order.reviewed">
            <div class="reviewForm__logo">
              <img class="logo" width="150" :src="this.store.logo" :alt="this.store.name" />
            </div>
            <div class="titleBlock -noMarginTop -center -compressed -small -light">
              <h1 class="titleBlock__title">Hola {{customer.name.replace(/ .*/,'')}}, al parecer <strong>ya habías dejado tu calificación sobre esta orden.</strong></h1>
              <p class="titleBlock__subtitle">Te invitamos a mirar nuestras promociones vigentes</p>
              <CButton :href="this.store.url" target="_blank" color="primary" class="button -big" >Ir a la tienda</CButton>
            </div>
            
          </div><!-- reviewForm__header -->
        <div v-else>

          
            <div class="reviewForm__header">
              <div class="reviewForm__logo">
                <img class="logo" width="150" :src="this.store.logo" :alt="this.store.name" />
              </div>
              <div class="titleBlock -noMarginTop -center -compressed -small -light">
                <h1 class="titleBlock__title">¿Cómo te sentiste <strong>comprando con nosotros?</strong></h1>
                <p class="titleBlock__subtitle">Dejá tu comentario valorando los productos para que podamos mejorar nuestro servicio</p>
              </div>
              
            </div><!-- reviewForm__header -->

            <form v-on:submit.prevent="sendForm" class="reviewForm__form ">

              <div :class="'reviewForm__items ' + (this.products.length > 1 ? 'grid -two' : '' )">

              <div :id="'item-'+product.id" v-bind:key="product.id" v-for="product in this.products" class="reviewForm__item item">
                <div class="item__product">
                  <div class="productCard -smallShadow">
                    <div class="productCard__image">
                      <img :src="product.image.src" :alt="product.name" />
                    </div>
                    <div class="productCard__info">
                      <h3 class="productCard__title">{{product.name}}</h3>
                      <p class="productCard__price">${{product.price.replace(/(\d+)([.,]0+)/, "$1")}}</p>
                    </div>
                  </div><!-- productCard -->
                </div><!-- item__product -->
                

                <div class="item__rating">
                  <CIcon :customClasses="'item__ratingFace'" name="cil-sad"></CIcon>

                    <label class="item__ratingInput -red">
                      <div class="item__ratingStar">
                        <div :class="product.rating && product.rating >= 1 ? 'circle -filled' : 'circle' " >

                        </div>
                      </div><!-- item__ratingStar -->
                      <input type="radio" v-model="product.rating"
                        name="rating"
                        value="1">
                        <div class="item__ratingNumber">1</div>
                    </label>

                    <label class="item__ratingInput -orange">
                      <div class="item__ratingStar">
                        <div :class="product.rating && product.rating >= 2 ? 'circle -filled' : 'circle' " >

                        </div>
                      </div><!-- item__ratingStar -->
                      <input type="radio" v-model="product.rating"
                        name="rating"
                        value="2">
                        <div class="item__ratingNumber">2</div>
                    </label>

                    <label class="item__ratingInput -yellow">
                      <div class="item__ratingStar">
                        <div :class="product.rating && product.rating >= 3 ? 'circle -filled' : 'circle' " >

                        </div>
                      </div><!-- item__ratingStar -->
                      <input type="radio" v-model="product.rating"
                        name="rating"
                        value="3">
                        <div class="item__ratingNumber">3</div>
                    </label>

                    <label class="item__ratingInput -green">
                      <div class="item__ratingStar">
                        <div :class="product.rating && product.rating >= 4 ? 'circle -filled' : 'circle' " >

                        </div>
                      </div><!-- item__ratingStar -->
                      <input type="radio" v-model="product.rating"
                        name="rating"
                        value="4">
                        <div class="item__ratingNumber">4</div>
                    </label>

                    <label class="item__ratingInput -greener">
                      <div class="item__ratingStar">
                        <div :class="product.rating && product.rating >= 5 ? 'circle -filled' : 'circle' " >

                        </div>
                      </div><!-- item__ratingStar -->
                      <input type="radio" v-model="product.rating"
                        name="rating"
                        value="5">
                        <div class="item__ratingNumber">5</div>
                    </label>
                    <CIcon :customClasses="'item__ratingFace'" name="cil-happy"></CIcon>

                    
                </div><!-- item__rating -->
                <div class="item__comments">
                  <CInput v-model="product.comments" placeholder="¿Qué te pareció?" maxlength="255" :id="'comment-'+product.id" :input="changeCommentHandler(product)" :isValid="product.commentIsValid" :description="product.commentIsValid ? 'Perfecto.' : 'Escribí por lo menos 1 palabra.'">
                  </CInput>
                </div>

              </div><!-- reviewForm__item -->

              </div><!-- reviewsForm__items -->

              <div class="reviewForm__customer">
              <div class="titleBlock -center -compressed -small -light">
                  <h2 class="titleBlock__title"><strong>¿Querés firmar la reseña?</strong></h2>
                  <p class="titleBlock__subtitle">Si querés podemos hacer que sea anónima, selecciona un casillero</p>
              </div>

                <div class="customer__name">
                  <CButtonToolbar>
                    <CButton class="button -big"  color="primary" :variant="this.customer_name_state == 'firstname' ? 'ghost' : 'outline' " v-on:click="customerNameHandler('firstname')" >{{this.customer_name.replace(/ .*/,'')}}</CButton>
                    <CButton class="button -big" :variant="this.customer_name_state == 'fullname' ? 'ghost' : 'outline' " color="primary" v-on:click="customerNameHandler('fullname')" >{{this.customer_name}}</CButton>
                    <CButton class="button -big" :variant="this.customer_name_state == 'anon' ? 'ghost' : 'outline' " color="primary" v-on:click="customerNameHandler('anon')" >Anonimo/a</CButton>
                  </CButtonToolbar>
                </div>
              </div>

              <div class="reviewForm__send">
                <CButton type="submit" color="primary" class="button -big">Enviar calificación</CButton>
                <div class="disclaimer">Al enviar su calificación acepta los <router-link class="link" to="/politicas">términos y condiciones.</router-link></div>
              </div>

            </form><!-- reviewForm__form -->

          </div><!-- render -->

        </div><!-- render -->

      </div><!-- container -->

      <div class="reviewForm__footer">
        Este formulario fue generado por <strong>CalificApp</strong> en nombre de <a target="_blank" :href="this.store.url">{{this.store.name}}</a>
      </div><!-- reviewForm__footer -->

      
    </section><!-- reviewForm -->


</template>

<script src="https://cdn.jsdelivr.net/gh/cferdinandi/smooth-scroll@15/dist/smooth-scroll.polyfills.min.js"></script>

<script>
import orderService from '@/services/order.service'
import storeService from '@/services/store.service'
import reviewService from '@/services/review.service'
import Loader from '@/views/components/Loader.vue';

const SmoothScroll = require('smooth-scroll');

const Swal = require('sweetalert2');

var CryptoJS = require("crypto-js");

export default {
  name: 'ReviewsForm',
  components: {
    Loader
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Califica tu experiencia de compra',
    // all titles will be injected into this template
    titleTemplate: null
  },
  data() {
    return {
      customer_name: '',
      customer_name_state: 'firstname',
      order: {},
      store: {},
      products: {},
      customer: {},
      isLoading: true,
      isSent: false,
      reviewResponse: {}
    }
  },
  created() {
    this.getOrder();
  },
  updated() {

  },
  methods: {
    getOrder: async function(){

      // Decrypt URL HASH For Order ID
      var bytes  = CryptoJS.AES.decrypt(this.$route.params.id.replace(/_/g, '/'), 'agenciaclica123');
      var decryptedId = bytes.toString(CryptoJS.enc.Utf8);

      const id = decryptedId;

      try {
        const response = await orderService.getOrder(id);

        const { data } = response.data;

        this.order = data[0];
        this.products = this.order.products;
        this.customer = this.order.customer;
        this.customer_name = this.order.customer.name;

        this.getStore();
      } catch (error) {
        console.log(error.message)
      }

      

    },
    
    getStore: async function(){
      const id = this.order.store_id;

      const response = await storeService.getStore(id);

      const { data } = response.data;
      
      this.store = data[0];

      this.isLoading = false;
    },

    customerNameHandler: function(name){
      switch (name) {
        case 'firstname':
          this.customer.name = this.customer.name.replace(/ .*/,'');
          break;
        case 'fullname':
          this.customer.name = this.customer.name;
          break;
        case 'anon':
          this.customer.name = 'Anonimo/a';
          break;
      
        default:
          this.customer.name = this.customer.name.replace(/ .*/,'');
          break;
      }

      this.customer_name_state = name;

    },

    hasReviews: function(){
      let hasReviews = false;
      this.products.forEach(item => {

        if('rating' in item){
          hasReviews = true;
        }

      });

      return hasReviews;
    },

    hasComment: function(item, index){
      if( 'comments' in item && item.comments.length > 0){

        return true;

      }else{

        this.$set(this.products[index], 'commentIsValid', false);

        var scroll = new SmoothScroll();
        var anchor = document.querySelector('#item-'+ item.id);
        scroll.animateScroll(anchor);

        return false;
      }
    },

    changeCommentHandler: function(product){
      if( 'comments' in product && product.comments.length > 1){
        product.commentIsValid = true;
      }else if('comments' in product){
        product.commentIsValid = false;
      }
    },

    sendForm: async function(){

      //Chequeo si hay por lo menos una review completada
      if(this.hasReviews()){

        this.products.forEach(async (item, index) => {

          // Solo envio el review si el usuario completo el rating

          if('rating' in item){

            //Chequeo si tiene un comentario, si no lo hago escribir uno

            if(!this.hasComment(item, index)){
              return;
            }

            //this.isLoading = true;

            const review = {
              store_id: this.store.store_id,
              product_id: item.product_id,
              order_id: this.order.order_id,
              rating: item.rating,
              comment: item.comments,
              customer_name: this.customer.name,
              status: 0,
            }

            const product = {
              store_id: this.store.store_id,
              product_id: item.product_id,
              name: item.name,
              image: item.image.src,
              rating: item.rating
            }

            const data = {
              product,
              review
            }

            try {

              const response = await reviewService.submitReview(data);

              this.isLoading = false;

              this.isSent = true;

              this.reviewResponse = response.data;

              this.$router.push({ name: 'ThankYou', params: {order: this.order, store: this.store, reviewResponse: this.reviewResponse}})
              
            } catch (error) {

              console.log(error);

              this.reviewResponse = error.response.data;

              this.$router.push({ name: 'ThankYou', params: {order: this.order, store: this.store, reviewResponse: this.reviewResponse}})
            }

            

          }
          
        });

      }else{
        Swal.fire({
          icon: 'error',
          title: 'Parece que no calificaste ningún producto...',
          text: 'Tenés que calificar al menos un producto para que podamos conocer tu experiencia de compra.',
          footer: 'No te va a tomar más de un minuto ☺',
          confirmButtonColor: '#3085d6'
        })
      }
    }

    
  },
}
</script>