<template>
  <section class="productos">

    <div class="productos__title">
      <div class="titleBlock -noMarginTop -noMarginBottom">
        <h1 class="titleBlock__title">
          Productos
        </h1>
        <p class="titleBlock__subtitle">
          Todos tus productos con calificaciones
        </p>
      </div>
    </div><!-- productos__title -->

    <Loader :absolute="false" v-if="isLoading">
    </Loader>

    <div v-if="!isLoading" class="productos__content">
      <CRow>
        <CCol sm="12" md="6" lg="4">
          <CInput
            v-model="searchQuery"
            label=""
            placeholder="Buscar productos..."
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol v-for="product in productsResult" :key="product.product_id" xxl="3" xl="4" lg="4" md="6" sm="12">
          <CCard >
            <CCardImg orientation="top" :src="product.image" />
            <CCardHeader>
              <CCardTitle tag="span">
                {{product.name}}
              </CCardTitle>
            </CCardHeader>
            <CCardBody>
              <span>Calificación general</span>
          
              <CProgress
                :value="product.rating"
                :color="ratingColor(product)"
                animated
                striped
                :precision="1"
                :max="5"
                show-value
                style="height:20px;"
                class="mt-1"
                size="xs"
              />
              <span class="small mb-2 mt-1 d-block">La calificación general del producto es el promedio de todas sus calificaciones.</span>

              <router-link :to="'/producto/' + product.product_id"><CButton class="mb-1" block color="primary">Ver calificaciones</CButton></router-link>
              <a target="_blank" :href="store.url + '/admin/products/' + product.product_id"><CButton block color="info">Ver en TiendaNube</CButton></a>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>

  </section><!-- productos -->
</template>

<script>
import productService from '../../services/product.service';

import { mapGetters } from 'vuex';
import Loader from '@/views/components/Loader.vue';

export default {
  name: 'Products',
   metaInfo: {
    title: 'Productos'
  },
  components: {
    Loader
  },
  data() {
      return {
          products: [{}],
          isLoading: true,
          searchQuery: null,
      }
  },
  created() {
    this.getProducts();
  },
  methods:{
    getProducts: async function(){

      const { store_id } = this.store;

      const response = await productService.getProducts(store_id);

      const { data } = response.data;

      this.products = data;

      this.isLoading = false;

    },

    ratingColor(product){
      
      const rating = product.rating;

      if(rating >= 4){
        return 'success';

      }else if(rating >= 3){
        return 'warning';

      }else if(rating >= 2){
        return 'danger';
      }
    
    },
  },
  computed: {
    ...mapGetters({
      config: 'getConfig',
      store: 'getStore',
    }),

    productsResult() {
      if (this.searchQuery) {
        return this.products.filter(product => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => product.name.toLowerCase().includes(v));
        });
      } else {
        return this.products;
      }
    },
    
  },
}
</script>
