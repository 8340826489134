import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex);

import general from './general.js'
import store from './store.js'
import config from './config.js'


export default new Vuex.Store({
    modules: {
        general,
        store,
        config
    }
});