import api from '../api';

const orderService = {

    getOrder(id) {
        return api.get(`/order?id=${id}`);
    },

    getOrders(store_id) {
        return api.get(`/order?store_id=${store_id}`);
    },
}

export default orderService;