<template>
  <section class="config">

    <Loader :absolute="false" v-if="isLoading">
    </Loader>

    <div v-if="!isLoading" class="config__content">
      <CRow>
        <CCol sm="12">
            <div class="titleBlock -small -noMarginBottom -noMarginTop">
              <h3 class="titleBlock__title">Información de tu tienda</h3>
            </div>
        </CCol>
        <CCol sm="12">
          <CForm v-on:submit.prevent="submitConfig">
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="4" >
                  <CInput
                    id="store_name"
                    v-model="store.name"
                    placeholder=""
                    label="Nombre de la tienda"
                    type="text"
                    required
                  />
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="4" >
                <label for="">Logo de la tienda</label>
                <br>
                <CImg width="150" :src="store.logo" ></CImg>
                <br><br>
                <div class="dropbox">
                  <input ref="fileinput" type="file" :disabled="isSavingLogo" @change="onLogoChange($event.target.files)" accept="image/*" class="input-file">
                  <p v-if="!isSavingLogo">
                    Arrastra tu logo aquí o haz click para seleccionarlo
                    <br>
                    <small>Tamaño máximo: 1MB (.jpg, .png, .svg)</small>
                  </p>
                  <p v-if="isSavingLogo">
                    Subiendo logo...
                  </p>
                </div><!-- dropbox -->
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                  <div class="titleBlock -small -noMarginBottom ">
                    <h3 class="titleBlock__title">Estado del pedido</h3>
                    <p class="titleBlock__subtitle">Configurá los estados de los pedidos sobre los cuales se van a enviar las solicitudes de calificación.</p>
                  </div>
              </CCol>
              
              <CCol xs="12" sm="12" md="6" lg="4">
                  <CSelect
                    id="order_status"
                    v-model="config.order_status"
                    :options="[ { value: 'any', label: 'Cualquier estado' }, { value: 'open', label: 'Abierto' }, { value: 'closed', label: 'Cerrado' } ]"
                    placeholder=""
                    description=""
                    label="Estado del pedido"
                    required
                  />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="4">
                  <CSelect
                    id="order_payment_status"
                    v-model="config.order_payment_status"
                    :options="[ { value: 'any', label: 'Cualquier estado' }, { value: 'pending', label: 'Pendiente' }, { value: 'authorized', label: 'Autorizado' }, { value: 'paid', label: 'Pago' } ]"
                    placeholder=""
                    description=""
                    label="Estado de pago del pedido"
                    required
                  />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="4">
                  <CSelect
                    id="order_shipping_status"
                    v-model="config.order_shipping_status"
                    :options="[ { value: 'any', label: 'Cualquier estado' }, { value: 'unfulfilled', label: 'Sin enviar' }, { value: 'fulfilled', label: 'Enviado' } ]"
                    placeholder=""
                    description=""
                    label="Estado de envio del pedido"
                    required
                  />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                  <div class="titleBlock -small -noMarginBottom">
                    <h3 class="titleBlock__title">Envío de correos</h3>
                    <p class="titleBlock__subtitle">Configurá las preferencias de envío de correo.</p>
                  </div>
              </CCol>
              
              <CCol xs="12" sm="12" md="6" lg="4">
                  <CInput
                    id="review_email_days"
                    v-model="config.review_email_days"
                    placeholder=""
                    description="Se recomienda un valor mayor al que tardás en enviar tus pedidos."
                    label="Días de demora para enviar el mail"
                    type="number"
                    min="1"
                    required
                  />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="4">
                  <CInput
                    id="email_replyto"
                    v-model="config.email_replyto"
                    placeholder=""
                    description="Email al que responderan los clientes en caso de tener un problema."
                    label="Correo electrónico de respuesta"
                    type="email"
                    required
                  />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                  <div class="titleBlock -small -noMarginBottom">
                    <h3 class="titleBlock__title">Aprobación de calificaciones</h3>
                    <p class="titleBlock__subtitle">Configura que calificaciones se aprueban automaticamente.</p>
                  </div>
              </CCol>
              
              <CCol xs="12" sm="12" md="6" lg="4">
                  <CInput
                    id="review_status_approve"
                    v-model="config.review_status_approve"
                    placeholder=""
                    description="Asegurate de que este valor concuerde con las calificaciones que te gustaría mostrar en tu tienda."
                    label="Aprobar calificaciones con puntuación mayor o igual a:"
                    type="number"
                    min="1"
                    required
                  />
              </CCol>
              <CCol sm="12">
                  <p class="mb-2">Acciones masivas:</p>
                  <CButton @click="masiveApprove()" color="info"  size="sm" shape="pill"  class=" mt-1">✔️ Aprobar todas las calificaciones por encima de "{{config.review_status_approve}}"</CButton>
                  <br>
                  <CButton @click="masiveDeny()" color="danger" size="sm" shape="pill"  class=" mt-3">❌ Ocultar todas las calificaciones por debajo de "{{config.review_status_approve}}"</CButton>
                  <p class="small mt-2">Estas acciones son irreversibles.</p>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                  <div class="titleBlock -small -noMarginBottom">
                    <h3 class="titleBlock__title">Visualización</h3>
                    <p class="titleBlock__subtitle">Ajustes de visualización de la app en tu tienda</p>
                  </div>
              </CCol>
              
              <CCol xs="12" sm="12" md="6" lg="4">
                  <CSelect
                    id="front_show_list"
                    v-model="config.front_show_list"
                    :options="[ { value: true, label: 'Mostrar' }, { value: false, label: 'No mostrar' } ]"
                    placeholder=""
                    description="Se muestra en la pantalla del producto"
                    label="¿Mostrar una lista con tus calificaciones debajo del producto?"
                    required
                  />
              </CCol>
            </CRow>
            <CButton type="submit" color="primary" class="button mt-5">Guardar cambios</CButton>
          </CForm>
        </CCol>
      </CRow>
    </div><!-- config__content -->

  </section><!-- config -->
</template>

<script>

import Loader from '@/views/components/Loader.vue';

import reviewService from '../../services/review.service';
import storeService from '../../services/store.service';

const Swal = require('sweetalert2');

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Config',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Configuración | CalificApp',
    // all titles will be injected into this template
    titleTemplate: null
  },
  components: {
    Loader
  },
  data() {
      return {
          logo: '',
          isSavingLogo: false,
      }
  },
  created() {

    this.loadConfig();

  },
  methods: {
    ...mapActions(['loadConfig','updateConfig','updateStore', 'updateLogo']),
    async onLogoChange(files) {
      
      const formData = new FormData();

      if (!files.length) return;

      formData.append('logo', files[0], files[0].name);

      Swal.fire({
        title: 'Actualizar logo',
        html: '¿Estás seguro de que quieres cambiar el logo por <strong>"'+ files[0].name +'"</strong>?',
        showDenyButton: true,
        confirmButtonText: 'Sí, continuar',
        denyButtonText: `No...`,
        icon: 'info'
      }).then( async (result) => {

        this.$refs.fileinput.value = null;
        
        if (result.isConfirmed) {
          
          Swal.fire({
              title: 'Actualizando logo...',
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });

          try {

            let { data } = await storeService.updateStoreLogo(this.store.store_id, formData);

            this.updateLogo(data.filename);
            console.log(this.store);
            
            Swal.fire({
              title: 'Logo actualizado correctamente.',
              timer: 2000,
              showConfirmButton: false,
              timerProgressBar: true,
              icon: 'success',
            });

          } catch (error) {

            Swal.fire({
                title: 'Hubo un error al actualizar el logo.',
                text: error.response.data.msg,
                timer: 3500,
                timerProgressBar: true,
                showConfirmButton: false,
                icon: 'warning',
              });

          }
        }
      });

      //this.logo = e.target.files[0];
    },
    async submitConfig() {
      try{  

        let configResponse = await this.updateConfig(this.config);

        let storeResponse = await this.updateStore(this.store);

        if(configResponse && storeResponse){
          Swal.fire({
            title: 'Configuración actualizada.',
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
            icon: 'success',
          });
        }else{
          Swal.fire({
            title: 'Hubo un error al actualizar la configuración.',
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
            icon: 'error',
          });
        }

      }catch(error){

        Swal.fire({
          title: 'Hubo un error al actualizar la configuración.',
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
          icon: 'error',
        });

        console.log('error', error);

      }
      
    },
    async masiveApprove() {

        Swal.fire({
        title: '¿Deseas continuar?',
        text: 'Vas a actualizar todas tus calificaciones mayores o iguales a "'+ this.config.review_status_approve +'" a estado aprobado.',
        showDenyButton: true,
        confirmButtonText: 'Sí',
        denyButtonText: `No...`,
      }).then( async (result) => {
        
        if (result.isConfirmed) {

          try {

            let {data} = await reviewService.updateStatusApproveAll(this.store);
            
            if( data.success && data.data.reviews.length > 0 ){
              Swal.fire({
                title: 'Calificaciones aprobadas masivamente.',
                text: data.data.reviews.length == 1 ? `Se actualizó ${data.data.reviews.length} calificación.` : `Se actualizaron ${data.data.reviews.length} calificaciones.`,
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true,
                icon: 'success',
              });
            }else if( data.success && data.data.reviews.length == 0 ){
              Swal.fire({
                title: 'No había calificaciones para actualizar.',
                timer: 1500,
                showConfirmButton: false,
                timerProgressBar: true,
                icon: 'info',
              });
            }else{
              Swal.fire({
                title: 'Hubo un error al actualizar las calificaciones.',
                timer: 1500,
                showConfirmButton: false,
                timerProgressBar: true,
                icon: 'error',
              });
            }

          } catch (error) {

            Swal.fire({
                title: 'Hubo un error al actualizar las calificaciones.',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
                icon: 'warning',
              });

          }
          
        }
      });
    },
    async masiveDeny() {

      Swal.fire({
        title: '¿Deseas continuar?',
        text: 'Vas a actualizar todas tus calificaciones menores a "'+ this.config.review_status_approve +'" a estado oculto.',
        showDenyButton: true,
        confirmButtonText: 'Sí',
        denyButtonText: `No...`,
      }).then( async (result) => {
        
        if (result.isConfirmed) {

          try {

            let {data} = await reviewService.updateStatusDenyAll(this.store);
            
            if( data.success && data.data.reviews.length > 0 ){
              Swal.fire({
                title: 'Calificaciones ocultadas masivamente.',
                text: data.data.reviews.length == 1 ? `Se actualizó ${data.data.reviews.length} calificación.` : `Se actualizaron ${data.data.reviews.length} calificaciones.`,
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true,
                icon: 'success',
              });
            }else if( data.success && data.data.reviews.length == 0 ){
              Swal.fire({
                title: 'No había calificaciones para actualizar.',
                timer: 1500,
                showConfirmButton: false,
                timerProgressBar: true,
                icon: 'info',
              });
            }else{
              Swal.fire({
                title: 'Hubo un error al actualizar las calificaciones.',
                timer: 1500,
                showConfirmButton: false,
                timerProgressBar: true,
                icon: 'error',
              });
            }

          } catch (error) {

            Swal.fire({
                title: 'Hubo un error al actualizar las calificaciones.',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
                icon: 'warning',
              });

          }
          
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      config: 'getConfig',
      store: 'getStore',
      isLoading: 'getLoadingState'
    }),
    
  },
}
</script>
