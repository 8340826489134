<template>
  <section class="producto">

    <div class="producto__header">
      <div class="producto__headerImage">
        <CImg :src="product.image" :alt="product.name" width="200" height="200"/>
      </div>
      <div class="producto__headerInfo">
        <h1 class="title">{{product.name}}</h1>
        <div class="calificaciones">
          <span>Calificación general</span>
          
          <CProgress
            :value="product.rating"
            :color="ratingColor"
            animated
            striped
            :precision="1"
            :max="5"
            show-value
            style="height:20px;"
            class="mt-1"
            size="xs"
          />
          <span class="small">La calificación general del producto es el promedio de todas sus calificaciones.</span>
          <div class="actions">
            <a target="_blank" :href="store.url + '/admin/products/' + product.product_id"><CButton color="info">Ver en TiendaNube</CButton></a>
          </div>
        </div>
      </div>
    </div><!-- producto__title -->

    
    <div class="producto__actions">
      <CRow>
        <CCol sm="12">
          <div class="titleBlock -small">
            <h2 class="titleBlock__title">
              Calificaciones del producto
            </h2>
            <p class="titleBlock__subtitle">
              Todas las calificaciones del producto se muestran acá
            </p>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CButtonGroup>
            <CButton @click="updateReviewsListStatus('all')" class="button" color="info">Todos</CButton>
            <CButton @click="updateReviewsListStatus(1)" class="button" color="success">Aprobados</CButton>
            <CButton @click="updateReviewsListStatus(0)" class="button" color="warning">Ocultos</CButton>
            <CButton @click="updateReviewsListStatus(2)" class="button" color="danger">En spam</CButton>
          </CButtonGroup>
        </CCol><!-- col -->
      </CRow><!-- row -->
    </div><!-- reviews__actions -->

    <Loader :absolute="false" v-if="isLoading">
    </Loader>

    <div v-if="!isLoading" class="reviews__content">
      <CRow>
        <CCol sm="12">
          <div class="dataTable">
            <CDataTable
            :items="reviews"
            :fields="fields"
            :items-per-page="25"
            :columnFilter="false"
            :items-per-page-select="{label: 'Items por página', values: [25, 50, 100, 500, 1000]}"
            :tableFilter="false"
            :sorterValue="{ asc: true }"
            :sorter='{ external: false, resetable: true }'
            :loading="isLoading"
            pagination
          >
          <template #order_number="{item}">
            <td>
              <a class="link -noUnderline" :href="store.url + '/admin/orders/' + item.order.order_id" target="_blank">#{{item.order.number}}</a>
            </td>
          </template>
          <template #rating="{item}">
            <td>
              <span>{{item.rating}} / 5</span>
            </td>
          </template>
          <template #comment="{item}">
            <td>
              <span v-if="item.comment">{{item.comment}}</span>
              <span v-else>Sin comentarios</span>
            </td>
          </template>
          <template #status="{item}">
            <td>
              <div class="badge-container">
                <CBadge :color="getBadgeColor(item.status)">{{parseStatusText(item.status)}}</CBadge>
              </div>
            </td>
          </template>
          <template #createdAt="{item}">
            <td>
              <span>{{new Date(item.createdAt).getDate() + '/' +(new Date(item.createdAt).getMonth() + 1 )+ '/'+new Date(item.createdAt).getFullYear()}}</span>
            </td>
          </template>
          <template #acciones="{item}">
            <td>
              <CButtonGroup >
                <CButton :key="item.status" v-if="item.status == 0 || item.status == 2" v-on:click="changeReviewStatus(1,item)" v-c-tooltip="'Aprobar'" color="success"><CIcon name="cil-check" /></CButton>
                <CButton :key="item.status" v-if="item.status == 1 || item.status == 2" v-on:click="changeReviewStatus(0,item)" v-c-tooltip="'Ocultar'" color="warning"><CIcon name="cil-low-vision" /></CButton>
                <CButton :key="item.status" v-if="item.status != 2" v-on:click="changeReviewStatus(2,item)" v-c-tooltip="'Enviar a spam'" color="danger"><CIcon name="cil-ban" /></CButton>
              </CButtonGroup>
            </td>
          </template>
          </CDataTable>
          </div><!-- table -->
        </CCol><!-- col -->
      </CRow><!-- row -->
      
    </div><!-- producto__content -->

  </section><!-- producto -->
</template>

<script>
import reviewService from '../../services/review.service';
import productService from '../../services/product.service';
import Loader from '@/views/components/Loader.vue';
const Swal = require('sweetalert2');
import { mapGetters } from 'vuex';

export default {
  name: 'Producto',
  components: {
    Loader
  },
  data() {
      return {
          fields: this.generateFields(),
          reviews: [{}],
          product: {},
          reviewsStatus: 'all',
          isLoading: true
      }
  },
  metaInfo: {
    title: 'Producto'
  },
  created() {
    this.getProduct();
    this.getReviews();
    

  },
  methods: {
    getReviews: async function(){
      const status = this.$route.query.status || this.reviewsStatus;
      const product_id = this.$route.params.product_id;

      this.reviewsStatus = status;

      const { store_id } = this.store;

      const response = await reviewService.getProductReviewsByStatus(store_id,product_id,this.reviewsStatus);

      const { data } = response.data;

      this.reviews = data;

      this.isLoading = false;

    },

    getProduct: async function(){
      const product_id = this.$route.params.product_id;

      const { store_id } = this.store;

      const response = await productService.getProduct(store_id,product_id);

      const { data } = response.data;

      this.product = data[0];

    },

    generateFields(){
      const fields = [{
        key: 'order_number' ,
        label: 'Pedido',
        filter: false,
      },{
        key: 'customer_name' ,
        label: 'Cliente',
        filter: false,
      },{
        key: 'comment' ,
        label: 'Comentario',
        filter: false,
      },{
        key: 'rating' ,
        label: 'Rating',
        filter: false,
      },{
        key: 'status' ,
        label: 'Estado',
        filter: false,
      },{
        key: 'createdAt' ,
        label: 'Fecha',
        filter: false,
      },{
        key: 'acciones' ,
        label: 'Acciones',
        filter: false,
      }];

      return fields;
    },

    updateReviewsListStatus: async function(status){
      this.reviewsStatus = status;

      this.$router.push({path: this.$router.path, query:{status: status}})

      this.getReviews();

    },
    getBadgeColor(status) {
      switch(status){
        case 0:
          return 'warning';
        case 1:
          return 'success';
        case 2:
          return 'danger';
      }
    },
    parseStatusText(status){
      switch(status){
        case 0:
          return 'Oculto';
        case 1:
          return 'Aprobado';
        case 2:
          return 'Enviado a spam';
      }
    },
    changeReviewStatus: async function(status, review){

      let statusText = this.parseStatusText(status); 

      Swal.fire({
        title: '¿Deseas continuar?',
        text: 'Vas a cambiar esta calificación a estado "'+statusText+'"',
        showDenyButton: true,
        confirmButtonText: 'Sí',
        denyButtonText: `No...`,
      }).then( async (result) => {
        
        if (result.isConfirmed) {

          review.status = status;

          try {

            await reviewService.updateStatus(review);

            Swal.fire({
              title: 'Calificación actualizada.',
              timer: 1500,
              showConfirmButton: false,
              timerProgressBar: true,
              icon: 'success',
            });

            this.updateReviewsListStatus(this.reviewsStatus);

          } catch (error) {

            Swal.fire({
                title: 'Hubo un error al procesar la solicitud.',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
                icon: 'warning',
              });

          }
          
        } else if (result.isDenied) {


          Swal.fire({
              title: 'Operación cancelada',
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
              icon: 'info',
            });

        }
      });

    },
  },
  computed: {
    ...mapGetters({
      config: 'getConfig',
      store: 'getStore',
    }),

    ratingColor(){
      
      const rating = this.product.rating;

      if(rating >= 4){
        return 'success';

      }else if(rating >= 3){
        return 'warning';

      }else if(rating >= 2){
        return 'danger';
      }

      return 'danger';
    
    },
  },
}
</script>
