import storeService from '@/services/store.service';


const state =  {
    store: JSON.parse(localStorage.getItem('store')) || {},
    token: localStorage.getItem('token') || '',
}

//Actions
const actions = {
    updateStore: async ({commit,getters,dispatch}, store) =>{

        dispatch('setLoading', true);

        const { store_id }  = getters.getStore;

        try {
            const { data } = await storeService.updateStore(store_id, store);

            dispatch('setLoading', false);

            localStorage.setItem('store', JSON.stringify(store));
            commit('SET_STORE', data.data);

            return true;
        }catch{
            dispatch('setLoading', false);

            return false;
        }

        
    },
    updateLogo: async ({commit,getters}, url) => {

        commit('SET_LOGO', url);
        localStorage.setItem('store', JSON.stringify(getters.getStore));
        
    },
    loadStore: async ({commit,dispatch}, payload) =>{

        const { store ,token } = payload;

        localStorage.setItem('token', token);
        localStorage.setItem('store', JSON.stringify(store));
        commit('SET_STORE', store);
        commit('SET_TOKEN', token);

        dispatch('loadConfig');

    },
    deleteStore: async ({commit}) =>{
        localStorage.removeItem('store');
        localStorage.removeItem('token');
        commit('SET_STORE', {});
        commit('SET_TOKEN', '');
    }
} 

//Getters
const getters = {
    getStore:  ( state ) => {
        return state.store
    }
} 

//Mutations
const mutations = {
    SET_LOGO: (state, url) => {
        state.store.logo = url;
    },
    SET_STORE: (state, data) => {
        state.store = data;
    },
    SET_TOKEN: (state, data) => {
        state.token = data;
    },
} 


const store = {
    actions,
    getters,
    mutations,
    state
}


export default store