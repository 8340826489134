import api from '../api'

const tnService = {

    auth(code) {
        return api.post(`/auth/tiendanube/`,{code});
    },
    
}

export default tnService;