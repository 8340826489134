var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pedidos"},[_vm._m(0),(_vm.isLoading)?_c('Loader',{attrs:{"absolute":false}}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"pedidos__content"},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"dataTable"},[_c('CDataTable',{attrs:{"items":_vm.orders,"fields":_vm.fields,"items-per-page":25,"columnFilter":false,"items-per-page-select":{label: 'Items por página', values: [25, 50, 100, 500, 1000]},"tableFilter":false,"sorterValue":{ column: 'createdAt', asc: false },"sorter":{ external: false, resetable: true },"loading":_vm.isLoading,"pagination":""},scopedSlots:_vm._u([{key:"order_number",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{staticClass:"link -noUnderline",attrs:{"href":_vm.store.url + '/admin/orders/' + item.order_id,"target":"_blank"}},[_vm._v("#"+_vm._s(item.number))])])]}},{key:"customer_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.customer.name))])])]}},{key:"customer_email",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.customer.email))])])]}},{key:"product_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"external-link"},[_c('router-link',{attrs:{"to":'/producto/' + item.product_id}},[_vm._v(_vm._s(item.product.name)+" "),_c('CIcon',{attrs:{"name":"cil-external-link"}})],1)],1)]}},{key:"sent_review",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"badge-container"},[_c('CBadge',{attrs:{"color":_vm.getBadgeColor(item.sent_review)}},[_vm._v(_vm._s(_vm.parseSentReviewText(item)))])],1)])]}},{key:"reviewed",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"badge-container"},[_c('CBadge',{attrs:{"color":_vm.getBadgeColor(item.reviewed)}},[_vm._v(_vm._s(_vm.parseReviewedText(item.reviewed)))])],1)])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(new Date(item.createdAt).getDate() + '/' +(new Date(item.createdAt).getMonth() + 1 )+ '/'+new Date(item.createdAt).getFullYear()))])])]}}],null,false,3864630095)})],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pedidos__title"},[_c('div',{staticClass:"titleBlock -noMarginTop -noMarginBottom"},[_c('h1',{staticClass:"titleBlock__title"},[_vm._v(" Pedidos ")]),_c('p',{staticClass:"titleBlock__subtitle"},[_vm._v(" Todos los pedidos de tu tienda ")])])])}]

export { render, staticRenderFns }