<template>

    <section class="reviewForm">
      <div class="container">
        <Loader v-if="isLoading">
        </Loader>

        <div  v-if="reviewResponse" class="reviewForm__notice">
          <div class="reviewForm__header" v-if="reviewResponse.success">
            <div class="reviewForm__logo">
              <img class="logo" width="150" :src="store.logo" :alt="store.name" />
            </div>
            <div class="titleBlock -noMarginTop -center -compressed -small -light">
              <h1 class="titleBlock__title"><strong>Gracias</strong> por dejarnos tu calificación {{reviewResponse.data.review.customer_name.replace(/ .*/,'')}}.</h1>
              <p class="titleBlock__subtitle">Es muy importante para que podamos seguir mejorando nuestro servicio.</p>
              <CButton :href="store.url" target="_blank" color="primary" class="button -big" >Ir a la tienda</CButton>
            </div>
            
          </div><!-- reviewForm__header -->

          <div class="reviewForm__header" v-if="!reviewResponse.success">
            <div class="reviewForm__logo">
              <div class="background-shape"><svg xmlns="http://www.w3.org/2000/svg" width="97" height="109" viewBox="0 0 97 109"><g transform="translate(-480 -135)"><circle cx="48.5" cy="48.5" r="48.5" transform="translate(480 147)" fill="#f0f0f0"/><circle cx="18.5" cy="18.5" r="18.5" transform="translate(540 135)" fill="#2eae84"/></g></svg></div>
              <img class="logo" width="150" :src="store.logo" :alt="store.name" />
            </div>
            <div class="titleBlock -noMarginTop -center -compressed -small -light">
              <h1 class="titleBlock__title">{{reviewResponse.data.review.customer_name.replace(/ .*/,'')}}, al parecer <strong>hubo un error</strong> procesando tu solicitud.</h1>
              <p class="titleBlock__subtitle">{{reviewResponse.msg}}</p>
              <CButton @click="goBack" target="_blank" color="primary" class="button -big" >Volver</CButton>
              <p class="titleBlock__disclaimer">
                Recomendamos que esperes unos segundos o te pongas en contacto con <a href="mailto:calificapp@clica.com.ar" class="link">un administrador</a>.
              </p>
            </div>
            
          </div><!-- reviewForm__header -->
        </div><!-- reviewForm__notice -->
        
        

      </div><!-- container -->

      <div class="reviewForm__footer">
        Este formulario fue generado por <strong>CalificApp</strong> en nombre de <a target="_blank" :href="this.store.url">{{this.store.name}}</a>
      </div><!-- reviewForm__footer -->
    </section><!-- reviewForm -->


</template>

<script>
import Loader from '@/views/components/Loader.vue';

export default {
  name: 'ThankYou',
  props: ['order', 'store', 'reviewResponse'],
  components: {
    Loader
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Gracias por calificar tu experiencia',
    // all titles will be injected into this template
    titleTemplate: null
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    if(!this.reviewResponse){
      this.$router.go(-1)
    }
  },
  updated() {

  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }

    
  },
}
</script>