<template>
  <div class="pagenotfound">

    <div class="container">
      
        <div class="titleBlock -center">
          <div class="titleBlock__image">
            <img width="50" src="assets/img/logo.svg" alt="CalificApp">
          </div>
          <h1 class="titleBlock__title">404</h1>
          <p class="titleBlock__subtitle">La página que estabas buscando no existe.</p>
          <div class="titleBlock__actions">
            <router-link to="/"><CButton class="button -big" color="primary">Volver a la app</CButton></router-link>
          </div>
        </div><!-- titleBlock -->

    </div><!-- container -->

  </div>
  
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Página no encontrada | CalificApp',
    // all titles will be injected into this template
    titleTemplate: null
  },
}
</script>
