const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    isLoading: false,
}

//Actions
const actions = {
    setLoading: async ({commit}, isLoading) =>{
        commit('SET_LOADING', isLoading);
    }
} 

//Getters
const getters = {
    getSidebarShow:  ( state ) => {
        return state.sidebarShow
    },
    getSidebarMinimize:  ( state ) => {
        return state.sidebarMinimize
    },
    getLoadingState:  ( state ) => {
        return state.isLoading
    }
} 

//Mutations
const mutations = {
    toggleSidebarDesktop (state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
        state[variable] = value
    },
    SET_LOADING: (state, isLoading) => {
        state.isLoading = isLoading
    },
} 


const store = {
    actions,
    getters,
    mutations,
    state
}


export default store