<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {
    
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Reviews para TiendaNube',
    // all titles will be injected into this template
    titleTemplate: '%s | Calificapp'
  },
  data() {
    return {
     
    }
  },
  created() {
    
  },
  methods: {
  }
    
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/main';
</style>
