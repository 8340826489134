import api from '../api';

const configService = {

    getConfig(store_id) {
        return api.get(`/config?store_id=${store_id}`);
    },

    updateConfig(store_id, config) {
        return api.post(`/config/update?store_id=${store_id}`,config);
    },
}
export default configService;