import api from '../api';

const productService = {

    getProduct(store_id,product_id) {
        return api.get(`/product/?store_id=${store_id}&product_id=${product_id}`);
    },

    getProducts(store_id) {
        return api.get(`/product/?store_id=${store_id}`);
    },
}

export default productService;