// Import swal
const Swal = require('sweetalert2');
import axios from 'axios'

const api = axios.create({
    baseURL: 'https://api.calific.app'
})

api.interceptors.request.use((config) => {
    config.headers = {
        authorization: localStorage.getItem('token'),
        'x-token-clica': 'agenciaclica123'
    }

    return config
});

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    const status = error.response?.status;

    if (status === 401) {

        Swal.fire({
            icon: 'error',
            title: 'Tu sesión ha expirado',
            text: 'Por favor inicia sesión nuevamente',
            type: 'warning',
            confirmButtonText: 'Continuar',
        }).then(async () => {
            localStorage.removeItem('token');
            localStorage.removeItem('store');
            location.reload();
        });
        
    }

    return Promise.reject(error);
});

export default api