import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
//import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import { freeSet as icons } from '@coreui/icons'
import VueMeta from 'vue-meta'
import Swal from 'sweetalert2';

Vue.config.performance = true
Vue.use(CoreuiVue);
Vue.use(VueMeta);
Vue.prototype.$log = console.log.bind(console);

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/tiendanube', '/customer/form', '/customer/gracias', '/politicas'];

  
  let authRequired = true;

  for(var p in publicPages){
    if(to.path.indexOf(publicPages[p]) > -1){
        authRequired = false;
    }
  }
  
  const loggedIn = localStorage.getItem('token');

  if (authRequired && (!loggedIn || loggedIn == 'null' || loggedIn == '')) {
    return next('/tiendanube');
  }

  next();
})

router.afterEach((to, from) => {

  if(to.query.registered){
    Swal.fire({
      icon: 'success',
      title: '¡Bienvenido!',
      html: 'Tu registro fue exitoso y ya estas listo para empezar a usar <strong>Calific<span style="color: #2EAE84;">App</span></strong>',
      type: 'success',
      confirmButtonText: 'Continuar',
    });
  }

});

new Vue({
  el: '#app',
  router,
  store,
  icons,
  beforeCreate() { },
  template: '<App/>',
  components: {
    App
  }
})