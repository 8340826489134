import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from '@/views/pages/Dashboard.vue'
import Reviews from '@/views/pages/Reviews.vue'
import Productos from '@/views/pages/Productos.vue'
import Producto from '@/views/pages/Producto.vue'
import Pedidos from '@/views/pages/Pedidos.vue'
import Config from '@/views/pages/Config.vue'
import Auth from '@/views/pages/Auth.vue'
import ReviewsForm from '@/views/customer/ReviewsForm.vue'
import ThankYou from '@/views/customer/ThankYou.vue'
import Politicas from '@/views/pages/Politicas.vue'
import PageNotFound from '../views/pages/PageNotFound.vue'
//import VueMeta from 'vue-meta'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/tiendanube',
      component: () => import( '../views/layouts/AuthLayout.vue'),
      children: [
        {
          path: "",
          name: 'Auth',
          component: Auth
        }
      ]
    },
    {
      path: '/politicas',
      component: () => import( '../views/layouts/BaseLayout.vue'),
      children: [
        {
          path: "",
          name: 'Politicas',
          component: Politicas
        }
      ]
    },
    {
      path: '/customer',
      component: () => import(  '../views/layouts/FormLayout.vue'),
      children: [
        {
          path: "form/:id",
          name: 'Reviews Form',
          component: ReviewsForm,
        },
        {
          path: "gracias",
          name: 'ThankYou',
          component: ThankYou,
          props: true
        }
      ]
    },
    {
      path: '/',
      redirect: '/calificaciones',
      name: 'Home',
      component: () => import('@/views/layouts/AppLayout'),
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/calificaciones',
          name: 'Calificaciones',
          component: Reviews,
        },
        {
          path: '/productos',
          name: 'Productos',
          component: Productos,
        },
        {
          path: '/producto/:product_id',
          name: 'Producto',
          component: Producto,
        },
        {
          path: '/pedidos',
          name: 'Pedidos',
          component: Pedidos,
        },
        {
          path: '/configuracion',
          redirect: '/configuracion/general',
          name: 'Configuracion',
          component: () => import('@/views/layouts/EmptyLayout'),
          meta: {title:'Configuración | CalificApp'},
          children: [
            {
              path: 'general',
              name: 'Configuracion general',
              component: Config
            },
            {
              path: 'correos',
              name: 'Configuracion de correos',
              component: Config
            }
        ]
        }
      ]
    },
    { path: '/404', component: PageNotFound },  
    { path: '*', redirect: '/404' },
  ]
}

