import configService from '@/services/config.service';


const state = {
    config: {}
};

//Actions
const actions = {
    loadConfig: async ({commit,rootGetters,dispatch}) =>{

        dispatch('setLoading', true);

        const { store_id }  = rootGetters.getStore;

        try {
            const { data } = await configService.getConfig(store_id);

            dispatch('setLoading', false);

            commit('SET_CONFIG', data.data[0]);
        }catch{
            dispatch('setLoading', false);
        }
        
    },
    updateConfig: async ({commit,rootGetters,dispatch}, config ) =>{

        dispatch('setLoading', true);

        const { store_id }  = rootGetters.getStore;

        try {
            const { data } = await configService.updateConfig(store_id, config);

            dispatch('setLoading', false);

            commit('SET_CONFIG', data.data);

            return true;
        }catch{
            dispatch('setLoading', false);

            return false;
        }
        
    }

} 

//Getters
const getters = {
    getConfig:  ( state ) => {
        return state.config
    }
} 

//Mutations
const mutations = {
    SET_CONFIG: (state, data) => {
        state.config = data;
    },
} 


const config = {
    actions,
    getters,
    mutations,
    state
}


export default config